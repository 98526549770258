<template>
  <div>
    <div class="jumbotron-wrapper color">
      <div class="jumbotron container-lg px-4">
        <div class="animation cover-text mt-5">
          <div class="row gx-5">
            <header class="mb-4">
              <p class="fs-1 fw-bolder mb-1">
                校招宣讲会｜行业前景，职业秘籍，学长学姐来分享
              </p>
              <div class="fst-italic mb-2">2022年4月14日</div>
              <a
                class="badge bg-secondary text-decoration-none link-light"
                href="#!"
                >校招</a
              >
              <a
                class="ms-1 badge bg-secondary text-decoration-none link-light"
                href="#!"
                >直播宣讲</a
              >
            </header>
          </div>
        </div>
      </div>
    </div>
    <div class="content p-auto">
      <section class="py-5">
        <div class="container-lg px-4">
          <div class="row">
            <div class="col-lg-3">
              <div class="d-flex align-items-center mt-lg-5 mb-4">
                <img
                  class="img-fluid rounded-circle logo_50"
                  src="../../assets/img/logo/logo_50.jpeg"
                  alt="非凸科技"
                />
                <div class="ms-3">
                  <div class="fw-bold">非凸新闻</div>
                  <div class="text-muted">Non-convex News</div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <article class="lh-lg">
                <section class="mb-5">
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/07/新闻封面.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    加入量化行业需要做哪些准备？
                  </p>
                  <p>
                    非凸科技为什么选择量化这条赛道？
                  </p>
                  <p>
                    量化行业未来发展会是怎样的？
                  </p>
                  <p>
                    应届生加入非凸将得到怎样的成长？
                  </p>
                  <p>
                    ......
                  </p>
                  <p>
                    为满足同学们的好奇心，4月14日晚19:00，非凸科技校园宣讲会与同学们相约“云端”，答疑解惑，干货满满。学长学姐现身分享，畅谈量化，亲授发展建议。
                  </p>
                  <h4 class="my-4">一、行业前景</h4>
                  <p>
                    非凸科技品牌部圆圆学姐从业务发展、团队背景、行业前景等方面做了全面分享，让同学们认识到非凸是一家有活力、有温度、有实力的高科技金融公司。
                  </p>
                  <p>
                    我们基于 Rust 生态，结合机器学习、深度学习等新兴技术，打造高效率、低延迟、高可靠、全内存高频交易平台，满足自身在量化行业交易业务的同时也为券商、量化私募等众多大型金融机构提供优质的算法支持。“工程开发和策略研究团队成员多数获得ACM/CMO/NOI等国际奖项。我们每一步的成就，离不开每一位成员。”
                  </p>
                  <p>
                    很多同学想了解非凸科技为什么要专注于量化研究，致力于算法交易？从宏观来看，全球对冲基金规模TOP10中有8家都是量化基金，而国内115家百亿私募中有28家量化私募，占比24%。大家也可以看到全球最大的对冲基金桥水的管理规模超1500亿美元，而国内量化基金整个行业的管理规模加起来仅略超桥水基金一家的管理规模。“通过数据可推断，中国目前的量化基金和算法交易发展阶段绝对是一片蓝海，未来10年将是A股市场算法交易发展的黄金期。”
                  </p>
                  <h4 class="my-4">二、职业秘籍</h4>
                  <p >
                    “人生的每一个节点都应做出期望最高的选择，但前提需要了解足够多的信息，加以分析判断，再进行决策。”非凸科技技术部一洲学长从历史发展和个人发展两个角度，分享了对量化行业的见解，以及职业发展建议。
                  </p>
                  <p >
                    从历史发展的角度来说，行业的选择很重要。计算机/数学等理工科背景的同学，多数会选择金融以及互联网，两个行业各具优势，而量化行业兼具了金融行业的稳定性以及互联网行业对技术的极致追求。据历史经验，每一个低谷期之后，往往会爆发出一个新兴行业，那么在这样的时期，中国将会做出怎样的新选择呢？近年来国内经济政策不断发出高科技的信号，由此诞生了很多优秀的高科技中小型企业，这也让股票等金融市场更加地活跃了。所以，当你做出行业选择的时候，或许应该选择一个正在爆发的市场。
                  </p>
                  <p >
                    从个人发展的角度来说，公司的选择也很重要。选择机会时，有些人会倾向于两个方面：一是稳定；二是尝试发展。对一洲学长而言，选择非凸，因为它具备：一是有较强的技术/研发资源背景，二是加入发展早期的公司会有更大的发展空间，三是分析判断非凸的业务前景极具优势。量化是一个目标导向较强的行业，需要通过历史数据来做历史分析，制定策略，从而使它在实际运转中赚取收益。所以对于非凸科技而言，给你自由成长的机会，勇于尝试新想法，竭尽自己所能，在最终的实盘中创造出你的收益。
                  </p>
                  <h4 class="mb-4">三、人才招聘</h4>
                  <p >
                    之后非凸科技人力资源部嘉轩学长介绍了未来的人才计划，分享了行业人才标准，未来发展前景，人才梯队建设等细节，让同学们从发展、生活、福利等方面认识了非凸科技。
                  </p>
                  <p >
                    非凸科技在上海、北京、成都、新加坡、美国均设置了办公室，均开放校园招聘岗位。主要考虑到：一是不同的城市或国家，会有不同的金融业务，除了算法交易之外，还有很多量化方面的研究；二是可以缩短与交易所的距离，有利于提高整体的交易速度，从软硬件追求极致的速度；三是可以聚拢到更多的优秀人才，因地制宜招到当地优秀的人才生源。另外，非凸对应届生和往届生不会做严格的限制，如果有足够的潜力，都有可能获得实习机会。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/07/校招live.jpg"
                      alt="人才招聘"
                    />
                  </figure>
                  <p>
                      非凸科技的招聘流程高效且流畅，反馈及时，基本一周内就可以得到最终结果。面向计算机/金融/物理/数学等相关专业的理工科同学，扎实的编程语言以及各大竞赛金银牌也是加分项。在非凸科技，我们主要使用Rust语言，既能提高工作效率，也能促进各部门及上下游的合作方便。Rust在高频量化交易领域的应用，非凸科技算是先驱者。如此同时，我们也带动了这股潮流，致力于推动国内计算机行业Rust的发展。
                  </p>
                  <p>
                      充足的研究资源，丰厚的福利待遇，平衡的工作与生活。“在非凸，你可以有更多的选择机会，可以参与多种可能性和挑战，最终选择适合自己的。”公司将为你提供业内有竞争力的薪酬和激励，让你每一份努力都收获满意的回报；帮你培养严谨、高效、细致的职业习惯，为你将来的发展铺平道路；提供体贴入微的人文关怀，让你在非凸的每一天都能享受工作。
                  </p>
                  <p>
                      广阔舞台，等你奔赴，一起见证未来！
                  </p>
                </section>
              </article>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "News01",
};
</script>

<style></style>
